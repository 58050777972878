import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const getimages = graphql`
  {
    imageOne: file(relativePath: { eq: "image-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    imageTwo: file(relativePath: { eq: "image-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    imageThree: file(relativePath: { eq: "image-3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    imageFour: file(relativePath: { eq: "image-4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const CollabarationSection = () => {
  const data = useStaticQuery(getimages);

  return (
    <section className="py-5">
      <Container>
        <h1 className="h4 text-center mb-3">Our Collaboration</h1>
        <Row>
          <Col md={3}>
            <Image
              className="image"
              fluid={data.imageOne.childImageSharp.fluid}
            />
          </Col>
          <Col md={3}>
            <Image
              className="image"
              fluid={data.imageTwo.childImageSharp.fluid}
            />
          </Col>
          <Col md={3}>
            <Image
              className="image"
              fluid={data.imageThree.childImageSharp.fluid}
            />
          </Col>
          <Col md={3}>
            <Image fluid={data.imageFour.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CollabarationSection;
