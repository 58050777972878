import React from 'react';
import { Link } from 'gatsby';

const VrifySection = () => {
  return (
    <section className="verify text-white">
      <div className="verify-flex">
        <h3 className="h2">VERIFY YOUR DRIVING LICENSE</h3>
        <div>
          <Link to="/verify/" className="btn btn-lg btn-light text-warning">
            VERIFY NOW
          </Link>
        </div>
      </div>
    </section>
  );
};

export default VrifySection;
