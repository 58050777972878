import React from 'react';
import Helmet from 'react-helmet';
import '../styles/index.css';

// components files
import Header from '../components/Header';
import Carosuel from '../components/CarouselSection';
import About from '../components/About';
import Varify from '../components/VrifySection';
import Collabaration from '../components/CollabarationSection';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <div id="wrapper">
      <Helmet>
        <title>WELCOME TO DLIMS GUJRANWALA</title>
      </Helmet>
      <Header />
      <Carosuel />
      <About />
      <Varify />
      <Collabaration />
      <Footer />
    </div>
  );
}
