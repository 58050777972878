import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

const About = () => {
  const [open, setOpen] = useState(false);
  const [secondOpen, setSecondOpne] = useState(false);
  const [thridOpen, setThridOpne] = useState(false);
  return (
    <section className="py-5">
      <Container>
        <Row>
          <Col md={6}>
            <h5>About Us</h5>
            <div
              style={{
                width: '40px',
                height: '2px',
                background: 'green',
                marginBottom: '10px',
              }}
            ></div>
            <Card>
              <Card.Header>
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-warning text-left"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  >
                    introduction
                  </button>
                </h2>
              </Card.Header>

              <Collapse in={open}>
                <div id="example-collapse-text">
                  <Card.Body>
                    <p className="text-lead">
                      Driving License Issuance Management System (DLIMS)
                      Phase-II automates the processes for driving license
                      issuance, renewal and upgrades. These activities are
                      carried out throughout the Gurjanwala. This system
                      provides quick processing service to public and up to date
                      statistics to the authorities by using state of the art
                      technology and equipment. The system redefines the
                      issuance process of all types of licenses by using a
                      centralized network.
                    </p>
                  </Card.Body>
                </div>
              </Collapse>
            </Card>
            <br />
            <Card>
              <Card.Header>
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-warning text-left"
                    onClick={() => setSecondOpne(!secondOpen)}
                    aria-controls="example-collapse"
                    aria-expanded={secondOpen}
                  >
                    Customer Care Standards
                  </button>
                </h2>
              </Card.Header>

              <Collapse in={secondOpen}>
                <div id="example-collapse">
                  <Card.Body>
                    <p className="text-lead">
                      The customer care standards will be enhanced in such a way
                      that the applicant will only have to visit the licensing
                      testing center and upon passing the test, the applicant
                      will get internationally renowned Secured Card based
                      driving license. In order to have a self-sustainable model
                      to continue giving best service delivery to the general
                      public, revolving fund will be created for inward and
                      outward transactions.
                    </p>
                  </Card.Body>
                </div>
              </Collapse>
            </Card>
            <br />
            <Card>
              <Card.Header>
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-warning text-left"
                    onClick={() => setThridOpne(!thridOpen)}
                    aria-controls="example"
                    aria-expanded={thridOpen}
                  >
                    Salient Features
                  </button>
                </h2>
              </Card.Header>

              <Collapse in={thridOpen}>
                <div id="example">
                  <Card.Body>
                    <p>
                      {' '}
                      Centralized Issuance and Management of Driving Licenses
                    </p>
                    <p>
                      Centralized Issuance and Management of Challan Ticketing
                      system
                    </p>
                    <p>
                      Centralized Database of Licensing System of whole
                      Gurjanwala
                    </p>
                    <p>
                      A hassle free approach towards applying and receiving a
                      Driving License
                    </p>
                    <p>Centralized Driving License Printing Facility</p>
                    <p>
                      Delivery of Issued Licenses through courier and basing on
                      that verification of the Applicant can be made easy
                    </p>
                    <p>Help reduce vehicle related crimes</p>
                    <p>Creating data hub for facilitate other agencies</p>
                    <p>Increase in revenue by ensuring transparency</p>
                  </Card.Body>
                </div>
              </Collapse>
            </Card>
          </Col>
          <Col md={6}>
            <h5>News</h5>
            <div
              style={{
                width: '40px',
                height: '2px',
                background: 'green',
                marginBottom: '10px',
              }}
            ></div>
            <Card>
              <Card.Header>
                <span>
                  {' '}
                  <span className="text-gray-dark">"</span> tralized DLIMS
                  system has been implemented Successfully receiving all the
                  license entries from all 36 districts
                </span>
              </Card.Header>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
