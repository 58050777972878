import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Image from 'gatsby-image';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';

const getImage = graphql`
  {
    file(relativePath: { eq: "showcase.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const CarouselSection = () => {
  const data = useStaticQuery(getImage);

  const {
    file: {
      childImageSharp: { fluid },
    },
  } = data;

  return (
    <Carousel>
      <Carousel.Item interval={1000}>
        <Image fluid={fluid} />
        <Carousel.Caption>
          <div className="mb-5 nano">
            <h3 className="display-4 font-weight-bold d-sm-none d-md-block">
              WELCOME TO <span className="text-warning">DLIMS</span>
            </h3>
            <p className="d-sm-none d-md-block">
              EASY WAY TO APPLY FOR THE DRIVING LICENSE.
            </p>
            <Link to="/howtoapply/" className="btn btn-warning text-white">
              HOW TO APPLY
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <Image fluid={fluid} />
        <Carousel.Caption>
          <div className="mb-5 nano">
            <h3 className="fontSize font-weight-bold d-sm-none d-md-block">
              TRACK LICENSE <span className="text-warning">DELIVERY</span>
            </h3>
            <p className="d-sm-none d-md-block">TRACK YOUR LICENSE STATUS.</p>
            <Button variant="warning" className="text-white">
              TRACK NOW
            </Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselSection;
